import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import InternalLink from '../components/InternalLink'
import ExternalLink from '../components/ExternalLink'
import getCardURLByType from '../utils/getCardURLByType'
import getCardConfigByType from '../utils/getCardConfigByType'

export default {
  componentType: 'table',
  objectName: 'actionFeed',
  sortLevel: 20,
  url: '/action_feed',
  endpoint: '/action_feed_app',
  title: 'action-feed',
  endpointParams: {
    'page[size]': 100,
    'page[number]': 1
  },
  table: [
    {
      title: 'action-feed.created_at',
      width: 140,
      sortBy: 'created_at',
      filterBy: 'date_from?filter[created_at]=action_feed',
      filterMode: 'date',
      fixed: true,
      getValue: data => {
        const date = data.createdAt
        if (!date) return null
        const colors = [
          '30bfb3',
          '416ec8',
          'ffbd40',
          'ff9340',
          'ce5a57',
          'b6452c',
          'ddc5a2'
        ]
        let color = '#' + colors[moment(date).format('e')]

        return (
          <span style={{ color }}>
            <FormattedMessage id={moment(date).format('dd')} />{' '}
            {moment(date).format('DD.MM HH:mm')}
          </span>
        )
      }
    },
    {
      title: 'action-feed.type',
      filterBy: 'kind?view[type]=nested',
      filterMode: 'multiple',
      filterKind: 'type',
      width: 120,
      fixed: true,
      getValue: data => {
        let cardConfig = getCardConfigByType(data.recordType)
        if (!cardConfig) {
          return ''
        }
        return <FormattedMessage id={cardConfig.title} />
      }
    },
    {
      title: 'action-feed.name',
      width: 300,
      fixed: true,
      getValue: data => data.name
    },
    {
      title: 'action-feed.collection',
      width: 300,
      filterBy: 'collection?page[size]=all&filter[relationships][institution][id]=all',
      filterMode: 'multiple',
      getValue: data => data.collectionName
    },
    {
      title: 'action-feed.viewed-by-current-user',
      width: 150,
      fixed: true,
      sortBy: 'viewed_by_current_user',
      filterBy: 'viewed_by_current_user',
      filterOptions: [
        {
          id: 'true',
          name: <FormattedMessage id="yes" />
        },
        {
          id: 'false',
          name: <FormattedMessage id="no" />
        }
      ],
      getValue: data => <FormattedMessage id={data.viewedByCurrentUser ? 'yes' : 'no'} />
    },
    {
      title: 'action-feed.card',
      width: 80,
      getValue: data => {
        if (data.action === 'destroy') return null
        let url = getCardURLByType({
          id: data.recordId,
          type: data.recordType
        })
        return <InternalLink
          url={url}
          text={data.slug}
          markVisited
          wasVisited={data.viewedByCurrentUser}
          logVisitEndpoint={`/action_feed/view-record/${data.id}`}
          visitDataPath={`/userAction/${data.id}/attributes/viewedByCurrentUser`}
        />
      }
    },
    {
      title: 'action-feed.site',
      width: 50,
      getValue: data => {
        if (!data.recordVisibleOnWeb) return null
        return <ExternalLink
          url={data.webUrlRaan}
          className={data.recordPublishedOnWeb ? 'ExternalLink__published' : ''}
        />
      }
    },
    {
      title: 'action-feed.action',
      width: 120,
      getValue: data => {
        return <FormattedMessage id={`action-feed.${data.action}`} />
      }
    },
    {
      title: 'action-feed.request_params_count',
      width: 120,
      getValue: data => Object.keys(data.requestParams).length
    },
    {
      title: 'action-feed.updated_by',
      width: 150,
      filterBy: 'user',
      filterKind: 'updated_by',
      sortBy: 'updated_by',
      getValue: data => data.updatedBy
    },
    {
      title: 'action-feed.created_by',
      width: 165,
      filterBy: 'user',
      filterKind: 'created_by',
      sortBy: 'created_by',
      getValue: data => data.createdBy
    }
  ]
}
